import type { Job, JobWithRelations } from '@factoryfixinc/ats-interfaces';

import { useJobStore } from './job.store';

import { useFFRestApi } from '@/composables/useApi';
import { arrayToRecord } from '@/utils/objects/array-to-record.util';
import type { Employer } from '@factoryfixinc/ats-interfaces';
import { JobPersistence } from '@/core/shared/job/job.persistence';

export default class JobService {
  private store = useJobStore();
  private readonly jobPersistence = new JobPersistence();

  public get jobs(): Job[] {
    return this.store.jobs;
  }

  public get jobsRecord(): Record<number, Job> {
    return arrayToRecord(this.store.jobs, 'id');
  }

  public async fetchJobs(jobIds: number[], employerId: Employer['id']): Promise<Job[]> {
    const filter = {
      where: {
        id: jobIds,
      },
    };
    const { data, error } = await useFFRestApi(
      `employer/${employerId}/job/summary?filter=${encodeURIComponent(JSON.stringify(filter))}`,
    ).json<Job[]>();

    if (error.value) {
      throw new Error(error.value);
    }

    if (!data.value) {
      throw new Error('No jobs found');
    }

    const jobs = data.value;
    this.store.jobs = jobs;

    return jobs;
  }

  async getById(id: number): Promise<JobWithRelations<'jobStatusHistories'> | undefined> {
    return this.jobPersistence.getByIdWithRelations(id, ['jobStatusHistories']);
  }
}
