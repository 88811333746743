import { useCookies } from '@vueuse/integrations/useCookies';
import AuthenticationService from '../core/shared/authentication/authentication.service';
import { type Router } from 'vue-router';
import MeService from '@/core/shared/me/me.service';
import SubscriptionService from '@/core/shared/subscription/subscription.service';
import JobTitleService from '@/core/shared/job-title/job-title.service';
import TaxonomyService from '@/core/shared/taxonomy/taxonomy.service';

export default async (router: Router): Promise<void> => {
  const cookies = useCookies();
  const authenticationService = new AuthenticationService();
  const meService = new MeService();
  const subscriptionService = new SubscriptionService();
  const jobTitleService = new JobTitleService();
  const taxonomyService = new TaxonomyService();

  const authTokenCookieName = import.meta.env.VITE_AUTH_TOKEN_COOKIE_NAME;
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  const ui2SelectedEmployerId = urlParams.get('ui2SelectedEmployerId');
  const route = window.location.pathname;

  // Keep route reference if present. Remove token and ui2SelectedEmployerId from query string.
  // don't remove the route where the user is going to be redirected
  urlParams.delete('token');
  urlParams.delete('ui2SelectedEmployerId');

  const query = {} as Record<string, string | string[]>;
  for (const [key, value] of urlParams.entries()) {
    if (!query[key]) {
      query[key] = value;
    } else {
      const keyValue = query[key];
      if (Array.isArray(keyValue)) {
        keyValue.push(value);
      } else {
        query[key] = [keyValue, value];
      }
    }
  }

  cookies.remove(authTokenCookieName);

  try {
    authenticationService.setToken(token);
    await meService.fetchMe();

    if (ui2SelectedEmployerId) {
      meService.setSelectedEmployerId(Number(ui2SelectedEmployerId), { reload: false });
    }

    await meService.handleActiveEmployer();
    await subscriptionService.fetchSubscription();
  } catch {
    authenticationService.logout();
    meService.redirectToUI2('/employer/auth/logout');
    return;
  }

  await Promise.all([
    taxonomyService.reload(),
    jobTitleService.fetchJobTitles(),
    meService.fetchEmployerUsers(),
  ]);

  await router.replace({ path: route, query, hash: window.location.hash });
};
