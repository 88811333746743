<script setup lang="ts">
import { computed, ref } from 'vue';
import FilterLinesSvg from '@/assets/svg/filter-lines-20.svg?component';
import SwitchInput from '@/components/Shared/FormInputs/SwitchInput/SwitchInput.vue';
import RadioGroupInput from '@/components/Shared/FormInputs/RadioGroupInput/RadioGroupInput.vue';
import TooltipArrow from '@/assets/svg/tooltip-arrow.svg?component';

import ConversationIndexService from '@/core/conversations/conversation-index/conversation-index.service';

import { SearchConversationOrderByFields } from '@/core/conversations/conversation-index/conversation-index.type';
import type { OrderByDirection } from '@/core/conversations/conversation-index/conversation-index.type';

const conversationIndexService = new ConversationIndexService();

const menu = ref(false);
const isMenuHovered = ref(false);
const sortDirection = ref(conversationIndexService.conversationIndexSearch.orderBy.direction);

const searchRef = computed(() => conversationIndexService.conversationIndexSearch);
const filterLabel = computed(() => {
  const {
    onlyUnread,
    onlyCurrentUser,
    orderBy: { field: orderByField },
  } = searchRef.value;
  if (onlyCurrentUser) {
    if (onlyUnread) {
      switch (orderByField) {
        case SearchConversationOrderByFields.LAST_MESSAGE_SENT_TS:
          return 'My most recent unreads';
        case SearchConversationOrderByFields.SCORE:
          return 'My unreads by score';
        case SearchConversationOrderByFields.APPLICATION_APPLY_TS:
          return 'My unreads by application date';
        default:
          return 'My unreads';
      }
    } else {
      switch (orderByField) {
        case SearchConversationOrderByFields.LAST_MESSAGE_SENT_TS:
          return 'My most recent conversations';
        case SearchConversationOrderByFields.SCORE:
          return 'My conversations by score';
        case SearchConversationOrderByFields.APPLICATION_APPLY_TS:
          return 'My conversations by application date';
        default:
          return 'My conversations';
      }
    }
  } else {
    switch (orderByField) {
      case SearchConversationOrderByFields.LAST_MESSAGE_SENT_TS:
        return 'Most recent conversations';
      case SearchConversationOrderByFields.SCORE:
        return 'Conversations by score';
      case SearchConversationOrderByFields.APPLICATION_APPLY_TS:
        return 'Conversations by application date';
      default:
        return 'Conversations';
    }
  }
});

const updateConversationsFilter = (newValue: string) => {
  const onlyCurrentUser = newValue === 'my';

  conversationIndexService.resetConversationIndexes();
  conversationIndexService.updateConversationIndexSearch({
    onlyCurrentUser,
    onlyUnread: !onlyCurrentUser ? false : searchRef.value.onlyUnread,
    pagination: {
      itemsPerPage: 25,
      page: 1,
    },
  });
};

const updateOrderByRef = (newValue: string) => {
  conversationIndexService.resetConversationIndexes();
  conversationIndexService.updateConversationIndexSearch({
    orderBy: {
      field: newValue as SearchConversationOrderByFields,
      direction: sortDirection.value,
    },
    pagination: {
      itemsPerPage: 25,
      page: 1,
    },
  });
};

const updateUnreadFilter = (newValue: boolean) => {
  conversationIndexService.resetConversationIndexes();
  conversationIndexService.updateConversationIndexSearch({
    onlyUnread: newValue,
    pagination: {
      itemsPerPage: 25,
      page: 1,
    },
  });
};

const updateMenuHovered = (value: boolean) => {
  isMenuHovered.value = value;
};

const toggleOrderDirectionRef = () => {
  const directionMap = {
    asc: 'desc',
    desc: 'asc',
  };
  sortDirection.value = directionMap[sortDirection.value] as OrderByDirection;
  updateOrderByRef(searchRef.value.orderBy.field);
};
</script>

<template>
  <div class="mb-2 flex justify-end">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      location="bottom right"
      transition="v-scroll-y-transition"
    >
      <template v-slot:activator="{ props }">
        <div v-bind="props">
          <div
            @mouseenter="updateMenuHovered(true)"
            @mouseleave="updateMenuHovered(false)"
            class="filter-activator flex cursor-pointer select-none items-center space-x-1 rounded-md border border-tint-80 bg-tint-0 px-2 py-1 text-shade-800 transition-colors hover:border-tint-100"
            :class="{ '!border-tint-100 bg-tint-20': menu || isMenuHovered }"
          >
            <div
              class="filter-activator-text text-xs font-semibold text-shade-800"
              :class="{ 'text-shade-900': menu || isMenuHovered }"
            >
              {{ filterLabel }}
            </div>

            <FilterLinesSvg
              :class="[menu || isMenuHovered ? 'text-shade-900' : 'text-shade-800']"
            />
          </div>
        </div>
      </template>

      <div
        class="filter-popover mt-1 flex w-60 flex-col space-y-4 rounded-lg bg-tint-0 px-4 py-3 shadow-lg"
      >
        <div>
          <label class="text-2xs font-extrabold uppercase text-shade-600">view</label>
          <SwitchInput
            label="Unreads only"
            :value="searchRef.onlyUnread"
            :disabled="!searchRef.onlyCurrentUser"
            @update:model-value="updateUnreadFilter"
          />
          <v-tooltip
            v-if="!searchRef.onlyCurrentUser"
            content-class="bg-shade-880 text-white !text-xs !py-2 !px-3 !rounded-md"
            activator="parent"
            location="bottom left"
            offset="-4"
          >
            <TooltipArrow alt="Tip" width="8" height="16" class="tooltip-arrow fill-shade-880" />
            <span class="mt-[2px] block">Not available for</span>
            <span class="font-semibold">All conversations</span>
          </v-tooltip>
        </div>
        <div class="space-y-2">
          <label class="text-2xs font-extrabold uppercase text-shade-600">conversations</label>
          <RadioGroupInput
            :items="[
              { label: 'All conversations', value: 'all' },
              { label: 'My conversations', value: 'my' },
            ]"
            :value="searchRef.onlyCurrentUser ? 'my' : 'all'"
            :on-change="updateConversationsFilter"
          />
        </div>
        <div class="space-y-2">
          <label class="text-2xs font-extrabold uppercase text-shade-600">sort</label>
          <RadioGroupInput
            :items="[
              {
                label: 'Most recent message',
                value: SearchConversationOrderByFields.LAST_MESSAGE_SENT_TS,
              },
              { label: 'Applicant Score', value: SearchConversationOrderByFields.SCORE },
              {
                label: 'Date of application',
                value: SearchConversationOrderByFields.APPLICATION_APPLY_TS,
              },
            ]"
            :value="searchRef.orderBy.field"
            :on-change="updateOrderByRef"
          />
        </div>
      </div>
    </v-menu>
    <div
      @click="toggleOrderDirectionRef"
      class="ml-1 flex min-h-8 min-w-8 cursor-pointer items-center rounded-md transition duration-300 hover:bg-tint-40"
      :class="{ 'rotate-180': sortDirection == 'desc' }"
    >
      <img src="@/assets/svg/arrows-up.svg" class="w-full" alt="Arrows up" />
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.filter-activator:hover {
  & .filter-lines-icon #icon {
    stroke: black transition-colors;
  }
  & .filter-activator-text {
    @apply text-shade-900 transition-colors;
  }
}
.filter-popover {
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.1);
}
.tooltip-arrow {
  transform: rotate(90deg);
  position: absolute;
  top: -10px;
}
</style>

<style lang="postcss">
.filter-popover {
  & .v-label {
    @apply p-1.5 text-sm leading-5 text-shade-880 opacity-100;
  }
  & .v-input--disabled {
    & .v-label {
      @apply text-tint-400 opacity-100;
    }
  }
}
</style>
