<script setup lang="ts">
import Editor from '@tinymce/tinymce-vue';
import { computed, onMounted, ref, watch } from 'vue';

import CopilotActivationService from '@/core/jobs/copilot-activation/copilot-activation.service';

import FormSection from './FormSection.vue';
import GenerateJobDescriptionAnimation from '@/components/Jobs/CopilotActivation/GenerateJobDescriptionAnimation.vue';
import MessageTemplate from '@/components/Shared/Input/Template/MessageTemplate.vue';
import {
  cleanHTMLJobDescription,
  makeMarkdownVersionOfJobDescription,
  removeTagsFromHTMLString,
} from '@/core/jobs/copilot-activation/utils/html-parsing.utils';
import TrackingService from '@/core/shared/tracking/tracking.service';
import { TrackingActionName } from '@/core/shared/tracking/tracking-actions';

type BtnApi = {
  setText: (text: string) => void;
};

const emit = defineEmits<{
  (e: 'init:editor'): void;
}>();

const props = defineProps<{
  disabled: boolean;
}>();

const firstLoad = ref(true);

const isDisabled = computed(() => {
  return props.disabled && !firstLoad.value;
});

const copilotActivationService = new CopilotActivationService();

const tinyMceAPIkey = import.meta.env.VITE_TINY_MCE_API_KEY;

const textValue = ref('');
const enableJobDescriptionGenerationBtn = ref<BtnApi>({ setText: () => {} });
const isFormDirty = ref(false);

const hasTriedToUpdateJob = computed(() => {
  return copilotActivationService.hasTriedToUpdateJob;
});

const showsErrorMessage = computed(() => {
  return (isFormDirty.value || hasTriedToUpdateJob.value) && textValue.value.trim() === '';
});
const isGeneratingJobDescription = computed(() => {
  return copilotActivationService.isGeneratingJobDescription;
});
const localJobDescription = computed(() => {
  return copilotActivationService.localJobDescription;
});

function handleInput(value: string) {
  // convert parsed html into Markdown
  let cleanData = removeTagsFromHTMLString(value, 'div');
  cleanData = removeTagsFromHTMLString(cleanData, 'hr');
  cleanData = cleanData.replace(/\n/g, '');

  const md = makeMarkdownVersionOfJobDescription(cleanData);

  copilotActivationService.description = cleanData;
  copilotActivationService.rawDescription = md;
}

async function generateJobDescription(api: BtnApi) {
  enableJobDescriptionGenerationBtn.value = api;

  if (isGeneratingJobDescription.value) {
    copilotActivationService.stopGeneratingJobDescription();
  } else {
    TrackingService.trackAction(TrackingActionName.JOB_DESCRIPTION_GENERATED, {
      project_id: copilotActivationService.selectedProjectId,
      job_id: copilotActivationService.selectedJobId,
    });
    await copilotActivationService.generateJobDescription();
  }
}

watch(textValue, (newValue) => {
  handleInput(`${newValue}`);

  if (newValue !== localJobDescription.value) {
    isFormDirty.value = true;
  }
});

// Some bug while updating the copilotActivationService.localJobDescription was
// not triggering this watch
watch(
  localJobDescription,
  (newValue) => {
    textValue.value = newValue;
  },
  { deep: true, immediate: true },
);

watch(isGeneratingJobDescription, (newValue) => {
  const shouldDeactivate = !newValue;

  const text = shouldDeactivate ? 'Generate my job description' : 'Stop generating';
  enableJobDescriptionGenerationBtn.value.setText(text);

  if (
    !newValue &&
    copilotActivationService.generatedJobDescriptionDocument?.jobDescription.fullfilled
  ) {
    // Set the generated data into the store
    const description = cleanHTMLJobDescription(
      copilotActivationService.generatedJobDescriptionDocument?.jobDescription
        .generatedJobDescription,
    );

    textValue.value = description;
  }
});

onMounted(() => {
  // The Editor or tinyMCE is not disabled on the first load
  // but the UI does refresh the :disable status after the first load
  // even if its FALSE it will keep the UI disabled
  // little hack to fix this issue
  setTimeout(() => {
    firstLoad.value = false;
  }, 500);
});
</script>

<template>
  <FormSection id="job-description" title="Add job description">
    <div
      :class="{
        generating: isGeneratingJobDescription,
      }"
    >
      <div class="relative">
        <Editor
          v-model="textValue"
          :api-key="tinyMceAPIkey"
          output-format="html"
          id="job-editor"
          :disabled="isDisabled"
          :init="{
            branding: false,
            elementpath: false,
            height: 500,
            menubar: false,
            contextmenu: ['copy', 'paste', 'link'],
            plugins: 'advlist powerpaste autolink lists link anchor',
            textpattern_patterns: [
              { start: '*', end: '*', format: 'italic' },
              { start: '**', end: '**', format: 'bold' },
              { start: '#', format: 'h1' },
              { start: '##', format: 'h2' },
              { start: '###', format: 'h3' },
              { start: '####', format: 'h4' },
              { start: '#####', format: 'h5' },
              { start: '######', format: 'h6' },
              { start: '---', replacement: '<hr/>' },
              // The following text patterns require the `lists` plugin
              { start: '1. ', cmd: 'InsertOrderedList' },
              { start: '* ', cmd: 'InsertUnorderedList' },
              { start: '- ', cmd: 'InsertUnorderedList' },
            ],
            toolbar: [
              { name: 'fontWeight', items: ['bold', 'italic'] },
              { name: 'itemLists', items: ['bullist', 'numlist'] },
              { name: 'copilotItems', items: ['copilotBtn'] },
            ],
            advlist_bullet_styles: 'disc',
            advlist_number_styles: 'default',
            setup: (editor: any) => {
              editor.ui.registry.addButton('copilotBtn', {
                text: 'Generate my job description',
                onAction: (api: BtnApi) => {
                  generateJobDescription(api);
                },
              });
            },
          }"
          @init="emit('init:editor')"
        />
        <GenerateJobDescriptionAnimation />
      </div>
      <div v-if="showsErrorMessage" class="v-field--error my-5 ml-4">
        <MessageTemplate message="Job description is required." class="text-[#B00020]" />
      </div>
    </div>
  </FormSection>
</template>

<style lang="scss" scoped>
:deep(.tox.tox-tinymce) {
  @apply rounded-md border border-tint-80;

  .tox-editor-header {
    box-shadow: none;
    @apply border-b border-solid border-[#E0E0E0];
  }
  .tox-statusbar {
    @apply border-transparent;
  }

  .tox-tbtn__select-label {
    @apply font-semibold;
  }

  .tox-toolbar__group[title='fontWeight'] {
    border-right: solid 1px #e0e0e0 !important;
  }

  .tox-toolbar__group[title='copilotItems'] {
    @apply ml-auto;
    button {
      @apply cursor-pointer bg-gradient-to-r from-[#AE35F7] to-highlight-600
      font-sans text-sm leading-4 text-tint-20;
      width: 210px !important;
    }
    button > * {
      @apply cursor-pointer;
    }
  }
}

.generating :deep(.tox.tox-tinymce) {
  .tox-toolbar__group[title='copilotItems'] {
    button {
      @apply border border-tint-80 bg-gradient-to-r from-transparent to-transparent text-shade-900;
    }
  }
}

:deep(.tox-tinymce--disabled) {
  @apply border-[1px] border-solid border-tint-80 bg-tint-40 opacity-85;

  .tox-editor-header,
  .tox-toolbar-overlord,
  .tox-toolbar__primary {
    @apply pointer-events-none;
    background-color: transparent !important;
  }
  .tox-toolbar__group[title='copilotItems'] {
    button {
      display: none;
    }
  }

  iframe,
  .tox-statusbar {
    background-color: transparent !important;
  }
}
</style>
