<script setup lang="ts">
import ProjectService from '@/core/shared/project/project.service';
import { computed, onBeforeMount, onBeforeUnmount, ref, watch } from 'vue';
import XCircleIcon from '@/assets/svg/snackbar/x-circle.svg?component';
import RefreshIcon from '@/assets/svg/refresh.svg?component';
import ProProfileService from '@/core/conversations/pro-profile/pro-profile.service';
import ConversationIndexService from '@/core/conversations/conversation-index/conversation-index.service';
import { getJobTitle } from '@/core/sourcing/utils/job';

const projectService = new ProjectService();
const proProfileService = new ProProfileService();
const conversationIndexService = new ConversationIndexService();

const jobTitle = ref('');

const currentConversations = computed(() => conversationIndexService.conversationIndexes);
const currentProjects = computed(() => projectService.projects);

const getProjectTitle = (applicationId: number) => {
  let jobTitle = '';
  try {
    const proProfile = proProfileService.selectedProProfile;
    const application = proProfile?.jobApplicants.find((app) => app.id === applicationId);
    jobTitle = getJobTitle(application?.job, false).displayTitle;
  } catch (e) {
    return jobTitle;
  }
  return jobTitle;
};

const handleRefreshToViewSavedJobs = async () => {
  projectService.isProjectFromDeepLinkRemoved = false;
  await projectService.searchProjects();

  if (projectService.projects.length !== 0) {
    const selectedProject = projectService.selectFirstProject();
    conversationIndexService.updateConversationIndexSearch({
      jobIds: [selectedProject?.jobId as number],
      pagination: {
        itemsPerPage: 25,
        page: 1,
      },
    });
  }
};

watch(currentConversations, (conversations) => {
  if (conversations.length !== currentConversations.value.length) {
    projectService.isProjectFromDeepLinkRemoved = false;
  }
});

watch(currentProjects, (projects) => {
  if (projects.length !== currentProjects.value.length) {
    projectService.isProjectFromDeepLinkRemoved = false;
    projectService.selectFirstProject();
  }
});

onBeforeMount(() => {
  const selectedApplication = proProfileService.selectedProApplication;

  if (!selectedApplication) {
    return;
  }
  jobTitle.value = getProjectTitle(selectedApplication.id);
});

onBeforeUnmount(() => {
  projectService.isProjectFromDeepLinkRemoved = false;
});
</script>

<template>
  <div class="flex w-full flex-col items-start gap-[12px] p-[11px]">
    <div class="flex flex-1 items-start gap-2 rounded-lg bg-critical-50 p-4">
      <XCircleIcon class="flex h-6 w-6 items-center justify-center text-critical-600" />
      <div class="flex flex-1 flex-col items-start gap-1">
        <span class="text-sm font-bold leading-[21px] text-critical-600"
          >This job was deleted.</span
        >
        <span v-if="jobTitle" class="text-sm font-normal leading-[21px] text-critical-600">
          The {{ jobTitle }} role that this candidate applied to has been deleted. To view this
          candidate again in the future, please use the link from the email.
        </span>
        <span v-else class="text-sm font-normal leading-[21px] text-critical-600">
          The job that this candidate applied to has been deleted. To view this candidate again in
          the future, please use the link from the email.
        </span>
      </div>
    </div>
    <div
      @click="handleRefreshToViewSavedJobs"
      class="flex cursor-pointer items-center justify-center gap-2"
    >
      <RefreshIcon class="flex h-5 w-5 cursor-pointer items-center justify-center text-tint-0" />
      <span class="text-sm font-bold leading-[20px] text-tint-0">Refresh to view saved jobs</span>
    </div>
  </div>
</template>
