import { useSnackbarStore } from './snackbar.store';
import type { Snackbar, UndoAction } from './types/snackbar.type';

export class SnackbarService {
  private store = useSnackbarStore();

  get snackbar(): Snackbar {
    return this.store.snackbar;
  }

  get undoAction(): UndoAction | undefined {
    return this.store.snackbar.undoAction;
  }

  set showUndo(show: boolean) {
    this.store.snackbar.showUndo = show;
  }

  public static showSnackbar(snackbar: Snackbar): void {
    const store = useSnackbarStore();
    let color = '';
    let textCss = ' text-sm font-bold ';

    switch (snackbar.variant) {
      case 'caution':
        color = 'shade-900';
        textCss += 'text-tint-0';
        snackbar.prependIcon = 'caution';
        break;
      case 'critical':
        color = 'shade-900';
        textCss += 'text-tint-0';
        snackbar.prependIcon = 'critical';
        break;
      case 'inform':
        color = 'inform-500';
        break;
      case 'primary':
        color = 'primary-500';
        break;
      case 'success':
        color = 'shade-900';
        textCss += 'text-tint-0';
        snackbar.prependIcon = 'success';
        break;
      default:
        color = 'shade-900';
        break;
    }

    store.snackbar = {
      ...snackbar,
      color,
      textCss,
      show: true,
    };
  }

  public static critical(text: string): void {
    SnackbarService.showSnackbar({
      variant: 'critical',
      text,
    });
  }

  public static success(text: string): void {
    SnackbarService.showSnackbar({
      variant: 'success',
      text,
    });
  }

  public static caution(text: string): void {
    SnackbarService.showSnackbar({
      variant: 'caution',
      text,
    });
  }

  public hideSnackbar(): void {
    this.store.snackbar = {
      show: false,
      showUndo: false,
      undoAction: undefined,
    };
  }
}
