<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  modelValue: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', payload: boolean): void;
  (e: 'exit'): void;
  (e: 'cancel'): void;
}>();

const isOpen = computed<boolean>(() => {
  return props.modelValue;
});

function handleUpdateModelValue(value: boolean) {
  emit('update:modelValue', value);
}

function handleCancel() {
  emit('cancel');
  handleUpdateModelValue(false);
}

function handleExit() {
  emit('exit');
  handleUpdateModelValue(false);
}
</script>

<template>
  <v-dialog :model-value="isOpen" max-width="600px" height="226px" persistent>
    <div class="rounded-lg bg-white p-6">
      <img
        class="float-right mt-1 cursor-pointer"
        src="@/assets/svg/close-black.svg"
        alt="close"
        width="20"
        height="20"
        @click="handleCancel"
      />
      <p class="mb-2 font-serif text-2xl font-black text-copilot">Exit without saving?</p>
      <p class="mb-12 font-sans font-normal text-shade-800">
        You are about to exit without saving your recent modifications.
      </p>
      <!-- Actions -->
      <div class="flex items-center justify-end">
        <v-btn
          :ripple="false"
          class="modal-button-secondary mr-4"
          variant="flat"
          @click.prevent="handleCancel"
          >Cancel</v-btn
        >
        <v-btn
          :ripple="false"
          class="modal-button-primary"
          variant="flat"
          @click.prevent="handleExit"
        >
          Exit without saving
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>
