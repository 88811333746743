<script setup lang="ts">
import MeService from '@/core/shared/me/me.service';
import SubscriptionService from '@/core/shared/subscription/subscription.service';
import { computed } from 'vue';
import { useDisplay } from 'vuetify';
import CreditsCounter from '../CreditsCounter/CreditsCounter.vue';
import JobSlotsCounter from '../JobSlotsCounter/JobSlotsCounter.vue';
import InviteTeammateMenu from '../InviteTeammate/InviteTeammateMenu.vue';
import UserMenu from '../UserInfo/UserMenu.vue';
import NavbarIcon from './NavbarIcon.vue';
import BriefCaseSvg from '@/assets/svg/conversations/briefcase-24.svg';
import BriefCaseFilledSvg from '@/assets/svg/conversations/briefcase-filled-24.svg';
import UserSearchSvg from '@/assets/svg/sourcing/user-search-24.svg';
import UserSearchFilledSvg from '@/assets/svg/sourcing/user-search-filled-24.svg';
import ReferralIcon from '@/assets/svg/referral-icon.svg';
import { DrawerService } from '@/core/shared/drawer/drawer.service';
import { useRoute } from 'vue-router';

const route = useRoute();
const display = useDisplay();
const meService = new MeService();
const subscriptionService = new SubscriptionService();
const drawerService = new DrawerService();

const isMobile = computed(() => display.smAndDown.value);
const isDesktop = computed(() => !isMobile.value);

const conversationsTarget = computed(() => {
  if (drawerService.visitedConversationsTabRouteName) {
    return {
      name: drawerService.visitedConversationsTabRouteName,
      query: route.query,
      params: route.params,
    };
  }

  return { name: 'conversations', query: route.query, params: route.params };
});
</script>

<template>
  <v-navigation-drawer v-if="isDesktop" color="shade-880" rail width="64px" permanent>
    <div class="flex h-full flex-col items-center justify-between p-2">
      <div>
        <!-- FactoryFix Logo -->
        <div class="flex h-12 w-12 items-center justify-center">
          <img
            src="@/assets/svg/ff-logo.svg"
            alt="FactoryFix"
            width="27"
            height="24"
            class="h-7 w-[27px]"
          />
        </div>

        <!-- Jobs Link -->
        <RouterLink :to="conversationsTarget" class="drawer-item" active-class="drawer-item-active">
          <NavbarIcon title="Jobs" :src="BriefCaseSvg" :selected-src="BriefCaseFilledSvg" />
        </RouterLink>

        <!-- Search Link -->
        <RouterLink to="/sourcing" class="drawer-item mb-0.5" active-class="drawer-item-active">
          <NavbarIcon title="Search" :src="UserSearchSvg" :selected-src="UserSearchFilledSvg" />
        </RouterLink>
      </div>

      <div
        v-if="meService.userProfile && meService.employer"
        class="flex w-12 flex-col items-center justify-center"
      >
        <div class="mb-2 border-y border-shade-840 py-4">
          <JobSlotsCounter
            class="drawer-item-bottom pb-4"
            :total="subscriptionService.jobSlots"
            :used="subscriptionService.usedJobSlots"
          />

          <CreditsCounter
            class="drawer-item-bottom"
            :total="subscriptionService.talentSearchCredits"
            :used="subscriptionService.usedTalentSearchCredits"
          />
        </div>

        <div class="drawer-item-bottom mb-2 flex items-center">
          <a href="https://get.factoryfix.com/rewards" target="_blank">
            <NavbarIcon
              title="Referral"
              :src="ReferralIcon"
              :selected-src="ReferralIcon"
              :no-hover="true"
            />
          </a>
        </div>

        <div class="drawer-item mb-2">
          <InviteTeammateMenu />
        </div>

        <div>
          <UserMenu :user="meService.userProfile" :employer="meService.employer" />
        </div>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<style scoped lang="scss">
.drawer-item {
  @apply flex h-12 w-12 items-center justify-center;
  @apply border-0 border-shade-840 transition-all duration-200;

  :deep(img) {
    @apply opacity-65;
  }
}

.drawer-item-active :deep(.icon-item) {
  @apply rounded-md border-[1px] border-shade-840 bg-shade-860;
}
.drawer-item-active :deep(img) {
  @apply opacity-100;
  background-image: var(--bkgImageSelected);
}
.drawer-item-bottom {
  @apply w-12 justify-center;
}
</style>
