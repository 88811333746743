<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import debounce from 'lodash/debounce';

import ChevronIcon from '@/assets/svg/conversations/ChevronIcon.vue';

import { type Project } from '@factoryfixinc/ats-interfaces';
import type { PlacePrediction } from '@/core/sourcing/search/types/place-prediction.type';

import { SearchService } from '@/core/sourcing/search/search.service';

import TrackingService from '@/core/shared/tracking/tracking.service';
import { TrackingActionName } from '@/core/shared/tracking/tracking-actions';
import { ErrorService } from '@/core/shared/errors/error.service';
import { SnackbarService } from '@/core/shared/snackbar/snackbar.service';
import ProjectService from '@/core/shared/project/project.service';
import router from '@/router';

const props = defineProps<{
  modelValue: boolean;
  project: Project;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', payload: boolean): void;
}>();

const searchService = new SearchService();
const projectService = new ProjectService();

const isEnablingCopilot = ref<boolean>(props.project.copilot);
const localSelectedJobTitle = ref<string>(props.project.title);
const localSelectedLocation = ref<string>(props.project.location);
const locationPredictions = ref<PlacePrediction[]>([]);
const populateAddressPredictions = debounce(_populateAddressPredictions, 300);
const isSendingJob = ref<boolean>(false);

const isOpen = computed<boolean>(() => {
  return props.modelValue;
});

const selectedProject = computed<Project>(() => {
  return props.project;
});

function handleUpdateModelValue(value: boolean) {
  emit('update:modelValue', value);
}

async function _populateAddressPredictions(address: string) {
  if (!address || !address?.trim()) {
    locationPredictions.value = [];
  } else {
    locationPredictions.value = await searchService.getAddressPredictions(address);
  }
}

async function handleEditJob() {
  try {
    isSendingJob.value = true;
    const enabledCopilot = isEnablingCopilot.value;

    if (enabledCopilot) {
      await TrackingService.trackAction(TrackingActionName.COPILOT_ENABLE_STARTED, {
        source: 'edit_job',
      });
    }

    await projectService.updateProject(props.project.id, {
      title: localSelectedJobTitle.value,
      location: localSelectedLocation.value,
    });

    await projectService.getProjectById(props.project.id);

    const project = projectService.projects.find((project) => project.id === props.project.id);

    if (project) {
      project.title = localSelectedJobTitle.value;
      project.location = localSelectedLocation.value;
    }

    handleUpdateModelValue(false);

    isSendingJob.value = false;
    await router.push(`/jobs/${props.project.id}/copilot-activation`);
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Failed to edit job. Please try again later.');
  } finally {
    isSendingJob.value = false;
  }
}

watch(selectedProject, (newValue) => {
  isEnablingCopilot.value = newValue.copilot;
  localSelectedJobTitle.value = newValue.title;
  localSelectedLocation.value = newValue.location;
});
</script>

<template>
  <v-dialog :model-value="isOpen" max-width="600px" height="550px" persistent>
    <div class="rounded-2xl bg-white p-8">
      <img
        class="float-right mt-1 cursor-pointer"
        src="@/assets/svg/close-black.svg"
        alt="close"
        width="20"
        height="20"
        @click="handleUpdateModelValue(false)"
      />
      <p class="font-serif text-2xl font-black text-copilot">Edit a saved job</p>
      <p class="mb-6 text-base font-normal text-shade-800">Change the job details visible to you</p>

      <!-- Title -->
      <div class="mb-6">
        <p class="mb-1 text-sm font-bold text-copilot">
          What job title are you looking to hire for?
        </p>
        <input
          v-model="localSelectedJobTitle"
          type="text"
          placeholder="eg. CNC Machinist"
          class="w-full rounded border-[1px] border-tint-80 px-3 py-2 text-sm focus:border-highlight-500 focus:outline-none"
        />
      </div>

      <!-- Location -->
      <div class="mb-3">
        <p class="mb-1 text-sm font-bold text-copilot">Where is the job located?</p>
        <v-combobox
          v-model:model-value="localSelectedLocation"
          :items="locationPredictions"
          item-title="value"
          item-value="value"
          variant="outlined"
          density="compact"
          class="text-sm"
          placeholder="eg. Chicago, IL"
          :return-object="false"
          :menu-props="{ maxWidth: '536px' }"
          @update:search="populateAddressPredictions"
          :menu-icon="ChevronIcon"
        >
        </v-combobox>
      </div>

      <!-- Actions -->
      <div class="flex items-center justify-end">
        <v-btn
          :ripple="false"
          class="modal-button-secondary"
          variant="flat"
          @click="handleUpdateModelValue(false)"
          >Cancel</v-btn
        >
        <v-btn
          :ripple="false"
          :disabled="isSendingJob"
          :loading="isSendingJob"
          class="modal-button-primary ml-4"
          variant="flat"
          @click="handleEditJob"
          >Save and continue</v-btn
        >
      </div>
    </div>
  </v-dialog>
</template>

<style scoped lang="postcss">
:deep(.v-field) {
  border-radius: 6px;

  .v-field__input {
    @apply text-sm;
  }
  .v-field__outline {
    &__start,
    &__end {
      opacity: 1;
      @apply border-y border-tint-80;
    }
  }

  &--focused {
    .v-field__outline {
      opacity: 1;
      &__start {
        @apply border-l border-highlight-500;
      }
      &__end {
        @apply border-r border-highlight-500;
      }
    }
  }
}
</style>
