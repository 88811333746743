import * as Sentry from '@sentry/vue';
import type { App } from 'vue';

export default (app: App): void => {
  if (import.meta.env.DEV) {
    return;
  }

  Sentry.init({
    app,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
  });
};
