import { useAuthenticationStore } from './authentication.store';
import { jwtDecode } from 'jwt-decode';
import MeService from '@/core/shared/me/me.service';
import { AuthenticationPersistence } from '@/core/shared/authentication/authentication.persistence';

export default class AuthenticationService {
  private readonly store = useAuthenticationStore();
  private readonly persistence = new AuthenticationPersistence();
  private readonly meService = new MeService();

  get isAuthenticated(): boolean {
    let isLoggedIn = false;

    if (this.store.accessToken && this.store.expiresAt) {
      if (Date.now() < this.store.expiresAt) {
        isLoggedIn = true;
      }
    }

    return isLoggedIn;
  }

  get token(): string | undefined {
    return this.store.accessToken;
  }

  setToken(token?: string | null): void {
    if (token) {
      this.store.accessToken = token;
    }

    if (!this.store.accessToken) {
      return;
    }

    const decodedToken = jwtDecode(this.store.accessToken);

    if (decodedToken.exp) {
      this.store.expiresAt = decodedToken.exp * 1000;
      this.scheduleTokenRefresh(this.store.expiresAt);
    }
  }

  logout(): void {
    clearTimeout(this.store.tokenRefreshTimerId);
    this.store.clear();
    this.meService.clear();
  }

  scheduleTokenRefresh(expiresAt: number): void {
    const refreshTime = this.calculateRefreshTime(expiresAt);

    this.store.tokenRefreshTimerId = setTimeout(() => {
      this.refreshToken();
    }, refreshTime);
  }

  async refreshToken(): Promise<void> {
    const token = await this.persistence.refreshToken();
    this.setToken(token);
  }

  private calculateRefreshTime(expiresAt: number) {
    const currentTime = new Date().getTime();
    const expiresIn = expiresAt - currentTime;
    const refreshIn = expiresIn - tokenRefreshBuffer;
    return Math.max(Math.min(refreshIn, tokenRefreshMaximum), tokenRefreshMinimum);
  }
}

/**
 * How many milliseconds before a token expires ought we try to refresh it? (use
 * 7190000 in testing to get a refresh every 10 seconds)
 */
const tokenRefreshBuffer = 5 * 60 * 1000;
/**
 * Wait at least one minute to refresh to avoid infinite loops with very short
 * token lifetimes
 */
const tokenRefreshMinimum = 1 * 60 * 1000;
/**
 * If token lifetime is larger than what a 32-bit integer can hold, we will
 * have issues with setTimeout. Therefore, we set a maximum of 2 hours here.
 *
 * refresh token every 2 hours whether it needs it or not
 */
const tokenRefreshMaximum = 120 * 60 * 1000;
