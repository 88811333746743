import { useFFRestApi } from '@/composables/useApi';
import { InternalError } from '../errors/internal.error';

export class AuthenticationPersistence {
  public async refreshToken(): Promise<string> {
    const { data, error, statusCode } = await useFFRestApi('/auth/refresh').get().json<{
      token: string;
    }>();

    if (error.value) {
      throw new InternalError('Could not refresh auth token', {
        error: error.value,
        status: statusCode.value,
      });
    }

    if (!data.value) {
      throw new InternalError('Empty response received from token refresh');
    }

    return data.value.token;
  }
}
