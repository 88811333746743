<script setup lang="ts">
import diamondGreen from '@/assets/svg/diamond-green.svg';
import SubscriptionService from '@/core/shared/subscription/subscription.service';
import { computed } from 'vue';

const subscriptionService = new SubscriptionService();
const showPremiumTag = computed(
  () => subscriptionService.usedJobSlots >= subscriptionService.jobSlots,
);
</script>

<template>
  <div
    v-if="showPremiumTag"
    class="flex h-[22px] w-[84px] items-center justify-center rounded-[24px] bg-shade-900 font-sans"
  >
    <img :src="diamondGreen" alt="Premium Diamond" height="16" width="16" />
    <span class="ml-1 text-[10px] font-bold leading-[18px]"> PREMIUM </span>
  </div>
</template>
