<script setup lang="ts">
import FileSvg from '@/assets/svg/file-20.svg?component';
import AssigneeWatchersList from '@/components/Shared/Projects/ProjectCard/ProjectAssigneeWatcherList.vue';
import ProjectArchivedStatus from '@/components/Shared/Projects/ProjectCard/ProjectArchivedStatus.vue';
import ProjectCopilotStatus from '@/components/Shared/Projects/ProjectCard/ProjectCopilotStatus.vue';
import ProjectAtsSyncStatus from '@/components/Shared/Projects/ProjectCard/ProjectAtsSyncStatus.vue';
import CountInformation from '@/components/Shared/Projects/ProjectCard/CountInformation.vue';
import NotificationBubble from '@/components/Shared/Notification/NotificationBubble.vue';
import ProjectActionsMenu from '@/components/Shared/Projects/ProjectCard/ProjectActionsMenu.vue';

import {
  JobApplicantStatus,
  type Project,
  ProjectStatus,
  type ProjectWithRelations,
} from '@factoryfixinc/ats-interfaces';
import { computed, ref } from 'vue';
import { DialogService } from '@/core/shared/dialog/dialog.service';

const props = defineProps<{
  project: ProjectWithRelations<'candidates'>;
  isCardSelected: boolean;
}>();

const emits = defineEmits<{
  (e: 'select-project', project: Project): void;
}>();

const dialogService = new DialogService();
const isActionsMenuOpen = ref(false);

const isArchived = computed(() => props.project.status === ProjectStatus.ARCHIVED);
const isCopilotActivated = computed(() => props.project.copilot);
const isAtsSynced = computed(() => props.project.remoteJobId !== null);
const atsLastSyncedTs = computed(() => `${props.project.lastSuccessfulSyncTs ?? ''}`);

const projectCandidatesCount = computed(() => props.project.candidates ?? {});

const newCandidatesCount = computed(() =>
  Math.max(projectCandidatesCount.value[JobApplicantStatus.NEW] ?? 0),
);
const engagedCandidatesCount = computed(() =>
  Math.max(projectCandidatesCount.value[JobApplicantStatus.CLIENT] ?? 0),
);
const reviewCandidatesCount = computed(() =>
  Math.max(projectCandidatesCount.value[JobApplicantStatus.REVIEW] ?? 0),
);

function selectProject() {
  emits('select-project', props.project);
}

function openProjectDescriptionOverlay() {
  dialogService.projectDescriptionOverlayProps = {
    isOpen: true,
    projectId: props.project.id,
    jobId: props.project.jobId,
  };
}
</script>

<template>
  <div
    class="project-card linear relative w-full"
    :id="`project-card-filter-job-${project.jobId}`"
    :class="{ 'project-card--selected': isCardSelected }"
    @keydown.exact.enter="selectProject"
  >
    <div>
      <div class="flex justify-between">
        <!-- Title -->
        <span class="truncate pr-4 text-[14px] font-bold leading-[21px] text-white"
          >{{ project.title }}
        </span>

        <NotificationBubble class="project-card__notification" :count="0" v-if="false" />

        <div class="flex items-center">
          <!-- Job description -->
          <v-tooltip location="top" content-class="project-card-tooltip tooltip-bottom-arrow">
            <template #activator="{ props }">
              <FileSvg
                class="mr-1 text-tint-400 hover:!text-white"
                v-bind="props"
                @click="openProjectDescriptionOverlay"
              />
            </template>
            <span class="text-xs">Job description</span>
          </v-tooltip>

          <!-- Job settings -->
          <v-tooltip location="top" content-class="project-card-tooltip tooltip-bottom-arrow">
            <template #activator="{ props }">
              <ProjectActionsMenu
                class="project-card__actions"
                v-model="isActionsMenuOpen"
                v-bind="props"
                :project="project"
              />
            </template>
            <span class="text-xs">Job settings</span>
          </v-tooltip>
        </div>
      </div>

      <!-- Location and ID -->
      <div class="mt-0.5 flex text-xs font-normal leading-[10px] text-tint-300">
        <span class="font-medium">{{ project.location }} | </span>&nbsp;#{{ project.jobId }}
      </div>
    </div>

    <div class="mt-3 flex items-center justify-between">
      <AssigneeWatchersList
        :assignee="project.assignee"
        :watchers="project.watchers"
        :project="project"
      />
    </div>

    <div class="mt-3 flex items-center">
      <CountInformation title="New" :count="newCandidatesCount" />
      <CountInformation title="Engaged" :count="engagedCandidatesCount" />
      <CountInformation title="Review" :count="reviewCandidatesCount" />

      <!-- Archived Status -->
      <ProjectArchivedStatus v-if="isArchived" class="absolute bottom-3 right-3" mini />

      <div v-else class="absolute bottom-3 right-3 flex">
        <!-- Copilot Status -->
        <ProjectCopilotStatus v-if="isCopilotActivated" mini />
        <!-- Ats Synced -->
        <ProjectAtsSyncStatus class="ml-1" v-if="isAtsSynced" mini :last-synced="atsLastSyncedTs" />
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.project-card {
  @apply relative mb-[7px] flex w-full cursor-pointer flex-col;
  @apply justify-center rounded-md border-l-4 border-shade-860 p-3;
  @apply transition-all duration-150 hover:border-shade-840 hover:bg-shade-840;

  &::after {
    content: '';
    position: absolute;
    bottom: -4px;
    right: 4%;
    left: 4%;
    height: 1px;
    @apply bg-shade-840 transition-all duration-150;
  }

  &.project-card--selected {
    @apply border-highlight-400 bg-shade-840;

    &::after {
      opacity: 0;
    }
  }
}
.project-card:hover {
  .project-card__notification {
    @apply bg-white text-shade-900 !important;
  }
}
.project-card:has(+ .project-card--selected) {
  &::after {
    opacity: 0;
  }
}
/** :last-child is not working for some reason */
.project-card:nth-last-child(-n + 2) {
  &::after {
    opacity: 0;
  }
}
</style>

<style>
/*
 * Must not be scoped since the component is moved to the beginning of the v-app
 * https://vuetifyjs.com/en/api/v-tooltip/#props-content-class
 */
.project-card-tooltip {
  padding: 0px !important;
  border-radius: 6px !important;
  padding: 3px 8px !important;
  background-color: #0f172a !important;
}
</style>
