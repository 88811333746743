<script lang="ts" setup>
import { computed } from 'vue';
import type { VMenu } from 'vuetify/components';
import XCircleIcon from '@/assets/svg/conversations/score/x-red.svg?component';
import AlertTriangleIcon from '@/assets/svg/conversations/score/alert-triangle.svg?component';
import CheckCircleIcon from '@/assets/svg/conversations/score/check-circle.svg?component';
import {
  type CriteriaColor,
  CriteriaColorEnum,
} from '@/core/conversations/application-score/application-score.type';
import TrackingService from '@/core/shared/tracking/tracking.service';
import { TrackingActionName } from '@/core/shared/tracking/tracking-actions';

type MenuProps = InstanceType<typeof VMenu>['$props'];

const props = withDefaults(
  defineProps<{
    popupLocation?: MenuProps['location'];
    chipTitle: string;
    chipText: string;
    color: CriteriaColor;
    shouldShowDialog: boolean;
    analyticsSource: string;
    applicationId: number;
    score: number;
  }>(),
  {},
);

const selectedColor = computed(() => props.color);
const colorInput = computed<{
  textBg: string;
  textColor: string;
}>(() => {
  if (selectedColor.value === CriteriaColorEnum.GREEN) {
    return {
      textBg: 'bg-primary-100',
      textColor: 'text-primary-600',
    };
  }

  if (selectedColor.value === CriteriaColorEnum.RED) {
    return {
      textBg: 'bg-critical-100',
      textColor: 'text-critical-800',
    };
  }

  return {
    textBg: 'bg-caution-100',
    textColor: 'text-caution-800',
  };
});

const shouldShowDialog = computed(() => props.shouldShowDialog);

function handleShowChipChange(show: boolean) {
  if (show) {
    TrackingService.trackAction(TrackingActionName.SCORE_SUMMARY_EXTRA_REASONING_VIEWED, {
      source: props.analyticsSource,
      applicationId: props.applicationId,
      score: props.score,
      type: props.chipTitle,
      reasoning: props.chipText,
    });
  }
}
</script>

<template>
  <v-menu
    :v-model="true"
    :close-on-content-click="false"
    :open-delay="0"
    :close-delay="0"
    attach
    @update:model-value="handleShowChipChange"
    :open-on-hover="true"
    :open-on-click="false"
    :location="(popupLocation as MenuProps['location']) || 'bottom'"
    width="304px"
  >
    <template v-slot:activator="{ props }">
      <span
        v-bind="props"
        class="justify-centerinline-block flex items-center rounded-full px-2 py-1.5 text-xs font-bold"
        :class="[colorInput.textBg, colorInput.textColor, { 'cursor-pointer': shouldShowDialog }]"
      >
        <CheckCircleIcon
          v-if="color === CriteriaColorEnum.GREEN"
          class="mr-1.5 h-4 w-4"
          :class="[colorInput.textColor]"
        />
        <XCircleIcon
          v-else-if="color === CriteriaColorEnum.RED"
          class="mr-1.5 h-4 w-4"
          :class="[colorInput.textColor]"
        />
        <AlertTriangleIcon v-else class="mr-1.5 h-4 w-4" :class="[colorInput.textColor]" />
        {{ chipTitle }}
      </span>
    </template>
    <template #default>
      <div
        :class="[popupLocation === 'top' ? 'mb-1' : 'mt-1']"
        class="rounded-xl bg-tint-0 p-3 shadow"
      >
        <div class="flex items-center space-x-2 pb-2">
          <span
            class="flex items-center justify-center rounded-full px-2 py-1.5 text-xs font-bold"
            :class="[colorInput.textBg, colorInput.textColor]"
          >
            <CheckCircleIcon
              v-if="color === CriteriaColorEnum.GREEN"
              class="mr-1.5 h-4 w-4"
              :class="[colorInput.textColor]"
            />
            <XCircleIcon
              v-else-if="color === CriteriaColorEnum.RED"
              class="mr-1.5 h-4 w-4"
              :class="[colorInput.textColor]"
            />
            <AlertTriangleIcon v-else class="mr-1.5 h-4 w-4" :class="[colorInput.textColor]" />
            {{ props.chipTitle }}</span
          >
        </div>
        <p class="self-stretch text-sm font-normal text-shade-900">
          {{ props.chipText }}
        </p>
      </div>
    </template>
  </v-menu>
</template>

<style scoped>
.shadow {
  box-shadow:
    0px 16px 64px -24px rgba(0, 0, 0, 0.2),
    0px 1px 2px -1px rgba(0, 0, 0, 0.2),
    0px 8px 40px -16px rgba(0, 0, 0, 0.1);
}
</style>
