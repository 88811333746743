<script setup lang="ts">
import CopilotLightningIcon from '@/assets/svg/jobs/copilot-lightning.svg?component';
import { computed } from 'vue';
const props = defineProps({
  isLoading: {
    type: Boolean,
    default: false,
  },
  isCopilotActivated: {
    type: Boolean,
    default: false,
  },
  isLoadingCopilot: {
    type: Boolean,
    default: false,
  },
  isJobVersionEditMode: {
    type: Boolean,
    default: false,
  },
  isAtsSynced: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits<{
  (e: 'updateJob', activate: boolean, byPassModal: boolean): void;
  (e: 'disableJobVersionEditing'): void;
  (e: 'disableCopilot'): void;
}>();

const isDisabled = computed<boolean>(() => {
  return props.isLoading || props.isLoadingCopilot;
});

const isSaveAndExitDisabled = computed<boolean>(() => {
  return isDisabled.value || (props.isAtsSynced && !props.isCopilotActivated);
});
</script>

<template>
  <div class="flex items-center justify-between">
    <div>
      <v-btn
        :ripple="false"
        :loading="isLoading"
        :disabled="isSaveAndExitDisabled"
        class="modal-button-secondary mr-4"
        variant="flat"
        @click.prevent="emit('updateJob', false, true)"
        >Save & Exit</v-btn
      >
      <v-btn
        v-if="isJobVersionEditMode"
        :ripple="false"
        class="modal-button-text ml-4"
        variant="flat"
        @click.prevent="emit('disableJobVersionEditing')"
        >Cancel</v-btn
      >
    </div>
    <div class="flex items-center">
      <v-btn
        v-if="!isCopilotActivated"
        :ripple="false"
        :loading="isLoadingCopilot"
        :disabled="isDisabled"
        class="copilot-activation-button"
        variant="flat"
        @click.prevent="emit('updateJob', true, false)"
      >
        <span>
          <CopilotLightningIcon class="mr-2" />
        </span>
        <span class="text-sm font-semibold leading-5 text-tint-20">Activate Copilot</span>
      </v-btn>
      <v-btn
        v-else
        :ripple="false"
        :loading="isLoadingCopilot"
        :disabled="isDisabled"
        class="modal-button-primary"
        variant="flat"
        @click.prevent="emit('disableCopilot')"
      >
        <span>
          <CopilotLightningIcon class="mr-2" />
        </span>
        <span class="text-sm font-semibold leading-5 text-tint-20">Disable Copilot</span>
      </v-btn>
    </div>
  </div>
</template>
<style></style>
