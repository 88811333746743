<script setup lang="ts">
import { computed } from 'vue';
import { SnackbarService } from '@/core/shared/snackbar/snackbar.service';

import {
  type SnackbarIcon,
  type SnackbarIconBinding,
} from '@/core/shared/snackbar/types/snackbar.type';

import CloseIcon from '@/assets/svg/conversations/CloseIcon.vue';
import TrashIcon from '@/assets/svg/conversations/TrashIcon.vue';
import PenIcon from '@/assets/svg/conversations/PenIcon.vue';
import AlertCircleIcon from '@/assets/svg/conversations/alert-circle.svg?component';
import AlertTriangleIcon from '@/assets/svg/snackbar/alert-triangle.svg?component';
import CheckCircleIcon from '@/assets/svg/snackbar/check-circle.svg?component';
import XCircleIcon from '@/assets/svg/snackbar/x-circle.svg?component';

const snackbarService = new SnackbarService();

const snackbar = computed(() => snackbarService.snackbar);
const snackbarPosition = computed(() => {
  const horizontalPosition = snackbar.value?.horizontalPosition || 'left';
  const verticalPosition = snackbar.value?.verticalPosition || 'bottom';
  return `${verticalPosition} ${horizontalPosition}` as const;
});

const prependIconProps = computed(() => iconBindings(snackbar.value?.prependIcon));
const snackbarPrependIcon = computed(() => renderIcon(snackbar.value?.prependIcon));
const appendIconProps = computed(() => iconBindings(snackbar.value?.appendIcon));
const snackbarAppendIcon = computed(() => renderIcon(snackbar.value?.appendIcon));

function iconBindings(icon?: SnackbarIcon) {
  let bindings: SnackbarIconBinding = {};
  if (icon === 'close') {
    bindings = {
      class: 'cursor-pointer',
      onClick: () => handleVisibilityChange(false),
    };
  }
  return bindings;
}

function renderIcon(icon?: SnackbarIcon) {
  if (!icon) return;
  switch (icon) {
    case 'close':
      return CloseIcon;
    case 'trash':
      return TrashIcon;
    case 'inform':
      return AlertCircleIcon;
    case 'caution':
      return AlertTriangleIcon;
    case 'success':
      return CheckCircleIcon;
    case 'critical':
      return XCircleIcon;
    case 'pen':
      return PenIcon;
    default:
      return;
  }
}

function handleVisibilityChange(value: boolean) {
  snackbar.value.show = value;
  snackbarService.showUndo = false;
}

async function handleUndoAction() {
  const fn = snackbarService.undoAction;
  if (fn) {
    await fn();
    handleVisibilityChange(false);
  }
}
</script>

<template>
  <v-snackbar
    :key="snackbar.timeout"
    :timeout="snackbar.timeout"
    :model-value="snackbar.show"
    :location="snackbarPosition"
    :color="snackbar.color"
    @update:model-value="handleVisibilityChange"
  >
    <div class="flex items-center justify-between space-x-2 p-1">
      <div class="flex items-center space-x-2">
        <div class="mr-1">
          <component
            v-if="snackbarPrependIcon"
            :is="snackbarPrependIcon"
            v-bind="prependIconProps"
            class="h-6 w-6 text-tint-0"
          />
        </div>
        <p
          class="snackbar-text"
          :class="snackbar.textCss"
          v-html="snackbar.text"
          data-test-id="snackbar-text"
        ></p>
      </div>
      <div class="flex items-center justify-end space-x-2">
        <span
          v-if="snackbar.showUndo"
          class="cursor-pointer text-sm font-bold text-tint-0"
          @click="handleUndoAction"
        >
          UNDO
        </span>
        <component
          v-if="snackbarAppendIcon"
          :is="snackbarAppendIcon"
          v-bind="appendIconProps"
          class="h-6 w-6 text-tint-0"
        />
      </div>
    </div>
  </v-snackbar>
</template>
<style>
/* Custom Snackbar border-radius for the whole website*/
.v-snackbar__wrapper {
  @apply !rounded-lg;
}
</style>
