<script setup lang="ts">
import rocketWhite from '@/assets/svg/rocket-white-16.svg';
import { computed } from 'vue';

const props = defineProps({
  mini: {
    type: Boolean,
    default: false,
  },
});

const isMiniVersion = computed(() => props.mini);
</script>
<template>
  <div
    class="flex items-center justify-center rounded bg-gradient-to-r from-highlight-600 to-[#AE35F7] p-1 text-[10px] font-bold leading-[10px] text-white"
  >
    <span v-show="!isMiniVersion" class="font-sans"> COPILOT </span>
    <v-tooltip
      v-show="isMiniVersion"
      location="top"
      content-class="bg-shade-880 tooltip-bottom-arrow"
    >
      <template v-slot:activator="{ props }">
        <img
          v-bind="props"
          alt="Copilot"
          v-show="isMiniVersion"
          :src="rocketWhite"
          class="inline-block"
          :class="{
            'ml-1': !isMiniVersion,
          }"
          height="12"
          width="12"
        />
      </template>

      <!-- Tooltip text -->
      <span class="text-xs">Copilot active</span>
    </v-tooltip>
  </div>
</template>
