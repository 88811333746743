<script setup lang="ts">
import { computed, nextTick, onBeforeUnmount, onMounted, reactive, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import ConversationIndexCard from '@/components/Conversations/ConversationIndex/ConversationIndexCard.vue';
import ConversationIndexLoaderCards from '@/components/Conversations/ConversationIndex/Loader/ConversationIndexLoaderCards.vue';
import ConversationIndexFilterEmptyResults from '@/components/Conversations/ConversationIndex/Filters/ConversationIndexFilterEmptyResults.vue';
import ConversationIndexStatusFilter from './Filters/ConversationIndexStatusFilter.vue';
import ConversationIndexPopoverFilter from './Filters/ConversationIndexPopoverFilter.vue';
import ConversationIndexSearchFilter from './Filters/ConversationIndexSearchFilter.vue';
import SpinnerLoader from '@/components/Shared/Loaders/SpinnerLoader.vue';
import ConversationBulkOptions from '@/components/Conversations/ConversationIndex/ConversationBulkOptions.vue';
import RejectedModal from '@/components/Conversations/Application/RejectedModal.vue';

import ConversationIndexService from '@/core/conversations/conversation-index/conversation-index.service';
import { ErrorService } from '@/core/shared/errors/error.service';
import { SnackbarService } from '@/core/shared/snackbar/snackbar.service';
import ProjectService from '@/core/shared/project/project.service';
import {
  ApplicationBulkActions,
  type BulkChangeApplicationStatusResult,
} from '@/core/conversations/conversation-index/conversation-index.type';
import ProProfileService from '@/core/conversations/pro-profile/pro-profile.service';
import { JobApplicantStatus } from '@factoryfixinc/ats-interfaces';
import TrackingService from '@/core/shared/tracking/tracking.service';
import { TrackingActionName } from '@/core/shared/tracking/tracking-actions';
import ProNotesService from '@/core/shared/pro-notes/pro-notes.service';
import { PromisePoolAllSettledStatus } from '@/utils/promise.util';
import { type CandidateExportInfo, exportXlsx } from '@/utils/export-xlsx-utils';

const projectService = new ProjectService();
const conversationIndexService = new ConversationIndexService();
const proProfileService = new ProProfileService();
const proNotesService = new ProNotesService();

const route = useRoute();
const router = useRouter();

const infiniteScrollElementRef = ref<HTMLElement | null>(null);
const infiniteScrollControl = reactive({
  underCoolDown: false,
  observer: null as IntersectionObserver | null,
});

const currentProject = computed(() => {
  return projectService.currentProject;
});
const currentProjectJobId = computed(() => projectService.currentProject?.jobId);
const conversationIndexes = computed(() => conversationIndexService.conversationIndexes);
const hasConversations = computed(() => conversationIndexes.value.length > 0);
const loadingConversationIndexes = computed(
  () => conversationIndexService.loadingConversationIndexes,
);
const selectedConversationIndex = computed(
  () => conversationIndexService.selectedConversationIndex,
);

const isBelowOrAboveSelectedConversation = (index: number): boolean => {
  if (!selectedConversationIndex.value || !conversationIndexes.value) return false;

  return [
    conversationIndexes.value[index]?.conversationId,
    conversationIndexes.value[index + 1]?.conversationId,
  ].includes(selectedConversationIndex.value?.conversationId);
};

const startConversationListInfiniteScrollObserver = () => {
  if (infiniteScrollControl.observer) {
    infiniteScrollControl.observer.disconnect();
  }

  infiniteScrollControl.observer = new IntersectionObserver(
    async (entries) => {
      entries.forEach(async (entry) => {
        // do nothing if there is a loading in progress to avoid messing the page state
        if (loadingConversationIndexes.value) {
          return;
        }

        if (!entry.isIntersecting) {
          // only allow the request trigger if the user scrolls up a bit
          infiniteScrollControl.underCoolDown = false;
        }

        if (infiniteScrollControl.underCoolDown) {
          return;
        }

        if (entry.isIntersecting && !infiniteScrollControl.underCoolDown) {
          await conversationIndexService.updateConversationIndexSearch({
            pagination: {
              itemsPerPage: 25,
              page: (conversationIndexService.lastPageWithResults || 0) + 1,
            },
          });
          // start cool down after a request has been triggered
          infiniteScrollControl.underCoolDown = true;
        }
      });
    },
    {
      threshold: 0.5,
    },
  );
  if (infiniteScrollElementRef.value) {
    infiniteScrollControl.underCoolDown = false;
    infiniteScrollControl.observer.observe(infiniteScrollElementRef.value);
  }
};

const handleDeepLinkFromSource = async (conversationId?: number) => {
  try {
    if (!conversationId) {
      SnackbarService.critical('Invalid deep link');
      return;
    }

    await conversationIndexService.fetchConversationIndexesByConversationId(conversationId);
  } catch (error) {
    SnackbarService.critical('Failed to load conversation');
  } finally {
    await router.replace({ path: route.path });
  }
};

const applyDeepLink = async () => {
  const jobId = Number(route.query?.jobId);
  const conversationId = Number(route.query?.conversationId);
  const jobApplicantId = Number(route.query?.jobApplicantId);
  const isComingFromSearch = route.query?.isComingFromSearch;

  if (!conversationId && !jobApplicantId) {
    SnackbarService.critical('Invalid deep link');
    return;
  }

  if (isComingFromSearch) {
    await handleDeepLinkFromSource(conversationId);
    return;
  }

  try {
    projectService.isSearchingProjects = true;
    conversationIndexService.loadingConversationIndexes = true;

    // Since we already check for conversationId and jobApplicantId, we can safely
    // assume that one of them will be present. And the preference is to fetch by jobApplicantId
    // that way we can get the right job.
    if (jobApplicantId) {
      await conversationIndexService.fetchConversationIndexesByApplicantId(jobApplicantId);
    } else {
      await conversationIndexService.fetchConversationIndexesByConversationId(
        conversationId,
        jobId,
      );
    }

    const jobIdFromConversationIndex = conversationIndexService.selectedConversationIndex
      ?.primaryApplicationIndex?.jobId as number;
    const jobIdToFetch = jobId || jobIdFromConversationIndex;
    await projectService.getProjectByJobIdFromDeepLink(jobIdToFetch);
  } catch (error) {
    SnackbarService.critical('Failed to load conversation');
  } finally {
    projectService.isSearchingProjects = false;
    conversationIndexService.loadingConversationIndexes = false;
    await router.replace({ path: route.path });
  }
};

function scrollSelectedConversationIntoView() {
  const selectedConversationId = selectedConversationIndex.value?.conversationId;

  if (!selectedConversationId) {
    return;
  }

  const selectedCardElement = document.getElementById(
    `conversation-index-card-${selectedConversationId}`,
  );

  selectedCardElement?.scrollIntoView({
    behavior: 'instant',
    block: 'center',
    inline: 'nearest',
  });
}

watch(loadingConversationIndexes, async (value) => {
  // Start infinite scroll observer when conversations are loaded so that we don't
  // send a search request when it's already loading conversations
  await nextTick();
  if (!value && hasConversations) {
    startConversationListInfiniteScrollObserver();
  }
});

watch(
  currentProjectJobId,
  async (newProjectJobId, oldProjectJobId) => {
    try {
      const isDeepLink = route.query?.isDeepLink;

      if (isDeepLink) {
        await applyDeepLink();
        return;
      }

      if (!newProjectJobId || newProjectJobId === oldProjectJobId) {
        return;
      }

      await conversationIndexService.updateConversationIndexSearch(
        { jobIds: [newProjectJobId] },
        { skipOnSameSearch: true, resetConversationIndexes: true, skipTracking: true },
      );
    } catch (error) {
      ErrorService.captureException(error);
      SnackbarService.critical('Could not fetch conversations. Please try again later.');
    } finally {
      conversationIndexService.loadingConversationIndexes = false;
    }
  },
  { immediate: true },
);

onMounted(() => {
  scrollSelectedConversationIntoView();
});

onBeforeUnmount(() => {
  if (infiniteScrollControl.observer) {
    infiniteScrollControl.observer.disconnect();
  }
});

const isSendingBulkAction = ref(false);
const rejectedModalVisible = ref(false);

const areAllConversationsSelected = computed(() => {
  if (!conversationIndexes.value.length) return false;
  return conversationIndexes.value.every((conversationIndex) => conversationIndex.isBulkSelection);
});
const areSomeConversationsSelected = computed(() => {
  return conversationIndexes.value.some((conversationIndex) => conversationIndex.isBulkSelection);
});

const handleSelectAll = (isSelectAll: boolean) => {
  conversationIndexService.toggleBulkActionAllConversationIndexes(isSelectAll);
};

const handleBulkAction = async (action: ApplicationBulkActions) => {
  if (isSendingBulkAction.value) return;

  if (!areAllConversationsSelected.value && !areSomeConversationsSelected.value) {
    SnackbarService.caution('Please select at least one conversation to perform this action.');
    return;
  }
  if (action === ApplicationBulkActions.MOVE_TO_REJECTED_W_REASON) {
    rejectedModalVisible.value = true;
    return;
  }

  if (action === ApplicationBulkActions.EXPORT_CANDIDATES) {
    exportCandidates();
    return;
  }

  changeBulkStatus(action);
};

const handleRejectedReason = (reason: string, isSilent: boolean) => {
  if (!reason) {
    SnackbarService.caution('Please provide a reason for rejection.');
    return;
  }
  rejectedModalVisible.value = false;
  changeBulkStatus(ApplicationBulkActions.MOVE_TO_REJECTED_W_REASON, isSilent, reason);
};

const changeBulkStatus = async (
  action: ApplicationBulkActions,
  isSilent: boolean = true,
  reason: string = '',
) => {
  let bulkResult: BulkChangeApplicationStatusResult | undefined = undefined;

  isSendingBulkAction.value = true;
  TrackingService.trackAction(TrackingActionName.BULK_ACTION_CHANGE_CONVERSATION_STATUS, {
    source: 'Conversations Index Filters',
    action,
    reason,
    isSilent,
  });

  try {
    SnackbarService.caution('Processing bulk action...');

    const prosToUpdate = await conversationIndexService.getBulkConversationIndexes();

    if (!prosToUpdate) {
      return;
    }

    const applicationIds = prosToUpdate.map((pro) => pro.applicationId);

    bulkResult = await proProfileService.bulkChangeApplicationStatus(
      applicationIds,
      action,
      isSilent,
      reason,
    );

    if (!bulkResult || bulkResult.successCount === 0) {
      throw new Error('Bulk action failed');
    }

    if (action === ApplicationBulkActions.MOVE_TO_REJECTED_W_REASON) {
      // We only add notes for the ones that were successfully updated
      const prosIdsToUpdate: number[] = [];
      bulkResult.result.forEach((result, index) => {
        if (result.status === PromisePoolAllSettledStatus.Fulfilled) {
          prosIdsToUpdate.push(prosToUpdate[index].proProfileId);
        }
      });

      if (prosIdsToUpdate.length > 0) {
        await proNotesService.bulkCreateProsRejectedNote(prosIdsToUpdate, reason);
      }
    }

    if (bulkResult.errorCount > 0) {
      SnackbarService.caution(
        `Bulk action complete, but ${bulkResult.errorCount} of
        ${
          bulkResult.errorCount + bulkResult.successCount
        } failed to change status. <br />Please try these individually.`,
      );
    } else {
      SnackbarService.success('Processing bulk action completed!');
    }
    // We need to refresh the candidates list and chip counts lets wait 1 second befor
    const statusList = conversationIndexService.selectedStatusList || [JobApplicantStatus.CLIENT];
    setTimeout(async () => {
      await projectService.getProjectById(currentProject.value?.id as number);
      await conversationIndexService.updateConversationIndexSearch({
        applicationStatus: statusList,
        pagination: {
          itemsPerPage: 25,
          page: 1,
        },
      });
      isSendingBulkAction.value = false;
    }, 500);
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Could not perform bulk action. Please try again later.');
    isSendingBulkAction.value = false;
  }
};

const exportCandidates = async () => {
  SnackbarService.caution('Processing export...');
  const bulkConversationIndexes = await conversationIndexService.getBulkConversationIndexes();
  const selectedApplicantIds =
    bulkConversationIndexes?.map((applicant) => applicant.applicationId) ?? [];

  TrackingService.trackAction(TrackingActionName.BULK_ACTION_EXPORT_APPLICANTS, {
    source: 'Conversations Index Filters',
    applicantsQty: selectedApplicantIds?.length,
  });

  try {
    const applicants = await proProfileService.getApplicantsByApplicationId(
      currentProjectJobId.value as number,
      selectedApplicantIds,
    );

    if ((applicants ?? []).length === 0) {
      SnackbarService.caution('No candidates selected for export.');
      return;
    }

    const fileName = !currentProject.value
      ? 'Candidates'
      : `FactoryFix ${currentProject.value.title} Candidates`;

    const exportInfo = {
      candidateData: applicants,
      jobId: currentProjectJobId.value,
      fileName: fileName,
      sheetName: 'Candidates',
    } as CandidateExportInfo;
    exportXlsx(exportInfo);
    SnackbarService.success('Export complete!');
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Could not export candidates. Please try again later.');
  }
};
</script>

<template>
  <div class="relative flex h-full max-h-screen w-full flex-col space-y-3 pt-4">
    <ConversationIndexSearchFilter />
    <ConversationIndexStatusFilter :project="currentProject" />
    <div class="flex items-center">
      <ConversationBulkOptions
        :are-all-selected="areAllConversationsSelected"
        :are-some-selected="areSomeConversationsSelected"
        :is-sending-bulk-action="isSendingBulkAction"
        @on-select-all="handleSelectAll"
        @on-bulk-action="handleBulkAction"
        class="mb-2 mr-1 flex self-center pl-2"
      />
      <ConversationIndexPopoverFilter class="flex-grow" />
    </div>
    <div class="flex w-full flex-col">
      <template v-if="loadingConversationIndexes && !hasConversations">
        <ConversationIndexLoaderCards />
      </template>
      <template v-else>
        <div
          v-for="(conversationIndex, index) in conversationIndexes"
          :key="conversationIndex.conversationId"
        >
          <ConversationIndexCard
            :conversationIndex="conversationIndex"
            @on-update-check="
              (checkValue: boolean) => (conversationIndex.isBulkSelection = checkValue)
            "
          />
          <div
            v-if="index !== conversationIndexes.length - 1"
            :class="{
              'border-white': isBelowOrAboveSelectedConversation(index),
              'border-tint-60': !isBelowOrAboveSelectedConversation(index),
            }"
            class="border-b"
          />
        </div>

        <ConversationIndexFilterEmptyResults
          :error-message="
            conversationIndexService.conversationIndexSearch.textual === ''
              ? 'Try changing your filter selections.'
              : 'Try using a different term'
          "
          v-if="!loadingConversationIndexes && !hasConversations"
        />

        <div class="mb-2 mt-8 flex items-center justify-center" ref="infiniteScrollElementRef">
          <SpinnerLoader :class="{ invisible: !loadingConversationIndexes }" />
        </div>
      </template>
    </div>
  </div>
  <RejectedModal
    v-model="rejectedModalVisible"
    @on-update-rejected-reason="handleRejectedReason"
  ></RejectedModal>
</template>
