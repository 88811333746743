import AuthenticationService from '@/core/shared/authentication/authentication.service';
import { createFetch } from '@vueuse/core';
import { HttpStatus } from '@/core/shared/errors/enums/http-status.enum';
import MeService from '@/core/shared/me/me.service';

type CreateFetchApiOptions = Partial<RequestInit> & {
  parseError?: boolean;
};

const createFetchApi = (url: string, fetchOptions?: CreateFetchApiOptions) => {
  // https://vueuse.org/core/useFetch/
  return createFetch({
    baseUrl: url,
    fetchOptions,
    options: {
      updateDataOnError: true,
      beforeFetch({ options }) {
        const authenticationService = new AuthenticationService();
        const token = authenticationService.token;

        options.headers = {
          ...(options.headers || {}),
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json; charset=utf-8',
        };

        return { options };
      },
      onFetchError(ctx) {
        if (fetchOptions?.parseError) {
          ctx.error = JSON.stringify({ ...ctx.error, ...ctx.data });
        }

        const status = ctx.response?.status ?? HttpStatus.INTERNAL_SERVER_ERROR;
        const isNotModified = status === HttpStatus.NOT_MODIFIED;
        const isUnauthorized = status === HttpStatus.UNAUTHORIZED;
        const shouldIgnoreError = isNotModified || isUnauthorized;

        if (shouldIgnoreError) {
          ctx.error = null;
        }

        if (isUnauthorized) {
          const authenticationService = new AuthenticationService();
          const meService = new MeService();
          authenticationService.logout();
          meService.redirectToUI2('/employer/auth/logout');
        }

        return ctx;
      },
    },
  });
};

export const useApiGateway = createFetchApi(import.meta.env.VITE_API_GATEWAY_URL);
export const useFFRestApi = createFetchApi(`${import.meta.env.VITE_FF_REST_API_URL}/v4`);
export const useAtsSubscriptionApi = createFetchApi(import.meta.env.VITE_ATS_SUBSCRIPTION_API_URL);
export const useAtsHelpDeskApi = createFetchApi(import.meta.env.VITE_ATS_HELP_DESK_API_URL);
export const useAtsEmployerApi = createFetchApi(import.meta.env.VITE_ATS_EMPLOYER_API_URL);
export const useAtsConversationApi = createFetchApi(import.meta.env.VITE_ATS_CONVERSATION_API_URL);
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAtsConversationApiWithOptions = (options?: CreateFetchApiOptions) =>
  createFetchApi(import.meta.env.VITE_ATS_CONVERSATION_API_URL, options);
export const useAtsProjectsApi = createFetchApi(import.meta.env.VITE_ATS_PROJECT_URL);
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAtsProjectsApiWithOptions = (options?: CreateFetchApiOptions) =>
  createFetchApi(import.meta.env.VITE_ATS_PROJECT_URL, options);
export const useAtsApplicationScoringApi = createFetchApi(
  import.meta.env.VITE_ATS_APPLICATION_SCORING,
);

export const useAtsCandidateDiscoveryApi = createFetchApi(
  import.meta.env.VITE_ATS_CANDIDATE_DISCOVERY_URL,
);

export const useAtsConnectApi = createFetchApi(import.meta.env.VITE_ATS_CONNECT_URL);

export const useAtsEmployerFeatureApi = createFetchApi(
  import.meta.env.VITE_ATS_EMPLOYER_FEATURE_API_URL,
);
export const useSubscriptionGatewayApi = createFetchApi(
  import.meta.env.VITE_SUBSCRIPTION_GATEWAY_URL,
);
export const useAtsUserPreferenceApi = createFetchApi(
  import.meta.env.VITE_ATS_USER_PREFERENCE_API_URL,
);
export const useJobBoardSearchApi = createFetchApi(import.meta.env.VITE_JOB_BOARD_SEARCH_API_URL);
export const useAtsJobApi = createFetchApi(import.meta.env.VITE_ATS_JOB_API_URL);
