import * as Sentry from '@sentry/vue';

export class ErrorService {
  public static captureException(exception: unknown): void {
    if (import.meta.env.DEV) {
      console.error(exception);
      return;
    }

    Sentry.captureException(exception);
  }
}
