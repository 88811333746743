<script setup lang="ts">
import { ScreeningAction } from '@factoryfixinc/ats-interfaces';
import { computed } from 'vue';
import { useDisplay } from 'vuetify';

import CopilotActivationService from '@/core/jobs/copilot-activation/copilot-activation.service';

import FormSection from './FormSection.vue';
import FFLogoSvg from '@/assets/svg/jobs/ff-logo.svg';
import SyncJobSvg from '@/assets/svg/jobs/sync-job.svg';
import { isRequired } from '@/utils/forms';
import MessageTemplate from '@/components/Shared/Input/Template/MessageTemplate.vue';

const display = useDisplay();
const copilotActivationService = new CopilotActivationService();

defineProps<{
  isDisabled: boolean;
}>();

const selectedAction = computed<ScreeningAction | null>(
  () => copilotActivationService.screeningAction || ScreeningAction.SCREEN,
);
const isDesktop = computed(() => display.mdAndUp.value);
const isScreenSelected = computed(() => selectedAction.value === ScreeningAction.SCREEN);
const isUrlSelected = computed(() => selectedAction.value === ScreeningAction.URL);
const isDisplayingCareerPageUrl = computed(() => selectedAction.value === ScreeningAction.URL);

function selectNewScreeningAction(action: ScreeningAction) {
  copilotActivationService.screeningAction = action;
}
</script>

<template>
  <FormSection id="screening-questions" title="Candidate screening">
    <div class="mb-6 flex flex-wrap">
      <!-- FF -->
      <div
        :class="{
          'mb-0 w-1/2': isDesktop,
          'mb-4 w-full': !isDesktop,
          'pointer-events-none opacity-75': isDisabled,
        }"
      >
        <div
          class="box-border cursor-pointer rounded-[4px] border border-tint-100 p-4"
          :class="{
            'mr-2 min-h-[274px]': isDesktop,
            'mr-0 min-h-[250px]': !isDesktop,
            '!border-highlight-500 bg-highlight-0': isScreenSelected,
          }"
          @click="selectNewScreeningAction(ScreeningAction.SCREEN)"
        >
          <figure class="relative mb-4 h-12">
            <img :src="FFLogoSvg" alt="FF Logo" />
            <span
              class="absolute right-0 top-0 h-[18px] rounded bg-highlight-500 p-1 text-[10px] font-bold leading-[10px] text-white"
            >
              RECOMMENDED
            </span>
          </figure>
          <p class="mb-2 font-sans text-base font-bold leading-6">
            Screen applicants in FactoryFix
          </p>
          <p class="font-sans text-sm font-normal leading-6">
            Add Screening Questions to easily uncover top candidates in FactoryFix. These questions
            will be automatically sent to applicants after they apply and won't appear in your job
            posting. This option is recommended.
          </p>
        </div>
      </div>
      <!-- Carrers page -->
      <div
        :class="{
          'min-h-[274px] w-1/2': isDesktop,
          'min-h-[250px] w-full': !isDesktop,
          'pointer-events-none opacity-75': isDisabled,
        }"
      >
        <div
          class="box-border min-h-[272px] cursor-pointer rounded-[4px] border border-tint-100 p-4"
          :class="{
            'ml-2 ': isDesktop,
            'mr-0': !isDesktop,
            '!border-highlight-500 bg-highlight-0': isUrlSelected,
          }"
          @click="selectNewScreeningAction(ScreeningAction.URL)"
        >
          <figure class="relative mb-4 h-12">
            <img :src="SyncJobSvg" alt="FF Logo" />
          </figure>
          <p class="mb-2 font-sans text-base font-bold leading-6">
            Ask applicants to apply directly on my careers page
          </p>
          <p class="font-sans text-sm font-normal leading-6">
            Choosing this option will notify pros via text/email to apply on your careers page.
            Applications won't sync with your ATS. Not recommended as many may not complete the
            process.
          </p>
        </div>
      </div>
    </div>

    <div v-if="isDisplayingCareerPageUrl">
      <p class="mb-0.5 font-sans text-xs font-bold leading-[18px] text-shade-880">
        Careers page URL*
      </p>
      <v-text-field
        v-model="copilotActivationService.employerCareerSiteUrl"
        placeholder="Enter the URL for your careers page"
        variant="outlined"
        density="compact"
        :rules="[isRequired]"
        :disabled="isDisabled"
      >
        <template #message="{ message }">
          <MessageTemplate :message="message" />
        </template>
      </v-text-field>
    </div>
  </FormSection>
</template>

<style lang="scss" scoped>
.v-input :deep(.v-field),
.v-input--horizontal :deep(.v-field) {
  border-radius: 6px;

  .v-field__outline {
    @apply text-tint-80;
  }
  input {
    @apply font-sans text-sm font-normal leading-[21px] text-shade-880;
  }
  input::placeholder {
    @apply text-shade-800;
    opacity: 1;
  }

  &:hover {
    .v-field__outline {
      @apply text-shade-840;
    }
  }
}

.v-input :deep(.v-field.v-field--focused),
.v-input--horizontal :deep(.v-field.v-field--focused) {
  .v-field__outline {
    @apply text-highlight-500;
  }
}
</style>
