<script setup lang="ts">
import MeService from '@/core/shared/me/me.service';
import { computed } from 'vue';
import { ref } from 'vue';

const meService = new MeService();
const selectedEmployerId = computed(() => meService.getSelectedEmployerId());
const inputValue = ref<string>();

const employers = computed(
  () =>
    meService.userProfile?.employerUserMaps.map((e) => ({
      id: e.employerId,
      name: e.employer.name,
    })) ?? [],
);

const filteredEmployers = computed(() =>
  employers.value.filter(
    (e) => e.name?.toLowerCase().includes(inputValue.value?.toLowerCase() ?? ''),
  ),
);

function isSelectedEmployer(employerId: number) {
  return employerId === selectedEmployerId.value;
}
</script>

<template>
  <div class="flex flex-col py-2">
    <div class="p-2">
      <v-text-field
        v-model="inputValue"
        placeholder="Search.."
        variant="outlined"
        density="compact"
        hide-details
        autofocus
      />
    </div>

    <div
      v-for="employer in filteredEmployers"
      :key="employer.id"
      :class="{
        'flex h-10 cursor-pointer items-center px-3 text-sm': true,
        'bg-highlight-50 font-bold': isSelectedEmployer(employer.id),
      }"
      @click="meService.setSelectedEmployerId(employer.id, { reload: true })"
    >
      <span>{{ employer.name }}</span>
    </div>
  </div>
</template>
