<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';

import ConversationIndexService from '@/core/conversations/conversation-index/conversation-index.service';

import ConversationIndexStatusFilterArrow from './ConversationIndexStatusFilterArrow.vue';
import ConversationIndexDispositionChip from '@/components/Conversations/ConversationIndex/Filters/ConversationIndexDispositionChip.vue';

import {
  JobApplicantStatus,
  type JobApplicantStatusCount,
  type ProjectWithRelations,
} from '@factoryfixinc/ats-interfaces';
import { useRoute } from 'vue-router';
import ProjectService from '@/core/shared/project/project.service';
import CopilotBubbleSvg from '@/assets/svg/copilot-bubble.svg';
import { EmployerFeatureService } from '@/core/shared/employer-feature/employer-feature.service';

const props = defineProps<{
  project?: ProjectWithRelations<'candidates'>;
}>();

const conversationIndexService = new ConversationIndexService();
const projectService = new ProjectService();

const route = useRoute();

const employerFeatureService = new EmployerFeatureService();

const filterContentWrapper = ref<HTMLElement | null>(null);
const showPreviousButton = ref(false);
const showNextButton = ref(true);
const statusFilterBaseOptions = [
  { text: 'Outreach', value: JobApplicantStatus.CONTACT, icon: CopilotBubbleSvg },
  { text: 'New', value: JobApplicantStatus.NEW },
  { text: 'Engaged', value: JobApplicantStatus.CLIENT },
  { text: 'Review', value: JobApplicantStatus.REVIEW },
  { text: 'Interview', value: JobApplicantStatus.INTERVIEW },
  { text: 'Offer', value: JobApplicantStatus.OFFER },
  { text: 'Hired', value: JobApplicantStatus.HIRED },
  { text: 'Rejected', value: JobApplicantStatus.REJECTED },
];

const statusFilterOptions = computed(() => {
  return employerFeatureService.hasCopilotOutreachEnabled
    ? statusFilterBaseOptions
    : statusFilterBaseOptions.filter(
        (statusFilterOption) => statusFilterOption.value !== JobApplicantStatus.CONTACT,
      );
});
const currentProject = computed(() => {
  return props.project;
});

const selectedStatusList = computed({
  get: () => conversationIndexService.selectedStatusList,
  set: (statusList: string[]) => {
    conversationIndexService.selectedStatusList = statusList;
  },
});

function setConversationIndexStatusSearch(statusList: string[]) {
  conversationIndexService.resetConversationIndexes();
  conversationIndexService.selectedStatusFilters = statusList;
  conversationIndexService.updateConversationIndexSearch({
    applicationStatus: statusList,
    pagination: {
      itemsPerPage: 25,
      page: 1,
    },
  });
}

function isSelected(statusFilterOption: string) {
  return selectedStatusList.value.includes(statusFilterOption);
}

function getStatusCandidatesCount(statusFilterOption: keyof JobApplicantStatusCount) {
  if (projectService.isProjectFromDeepLinkRemoved) {
    return 0;
  }

  const candidateCounts = currentProject.value?.candidates;
  let count = 0;

  if (candidateCounts) {
    count = candidateCounts[statusFilterOption] ?? 0;
  }

  return count;
}

/**
 * We remove multi-select functionality when we select a Status Filter but we
 * leave the rest of the code without any changes in case we need to get back
 * multi-select again.
 */
function selectStatus(statusFilterOptionSelected: string) {
  if (isSelected(statusFilterOptionSelected)) {
    selectedStatusList.value = selectedStatusList.value.filter(
      (selectedStatusFilterOption) => selectedStatusFilterOption !== statusFilterOptionSelected,
    );
  } else {
    selectedStatusList.value = [statusFilterOptionSelected];
  }
  setConversationIndexStatusSearch(selectedStatusList.value);
}

function setSelectedStatusToDefaultIfEmpty() {
  if (selectedStatusList.value.length === 0) {
    selectedStatusList.value = [JobApplicantStatus.CLIENT];
    setConversationIndexStatusSearch(selectedStatusList.value);
  }
}

onMounted(() => {
  const isDeepLink = route.query?.isDeepLink;

  if (!isDeepLink) {
    setSelectedStatusToDefaultIfEmpty();
  }

  const filterContentWrapperElement = filterContentWrapper.value as HTMLElement;

  filterContentWrapperElement.addEventListener('wheel', (e) => {
    e.preventDefault();
    const newScrollLeft = filterContentWrapperElement.scrollLeft + e.deltaX;
    filterContentWrapperElement.scrollLeft = newScrollLeft;
    filterContentWrapperElement.style.scrollBehavior = 'auto';
    updateArrowButtons(newScrollLeft);
  });
});

onBeforeUnmount(() => {
  const filterContentWrapperElement = filterContentWrapper.value as HTMLElement;
  filterContentWrapperElement.removeEventListener('wheel', () => {});
});

function updateArrowButtons(newScrollLeft: number) {
  const arrowButtonToleranceToHide = 20;

  const filterContentWrapperElement = filterContentWrapper.value as HTMLElement;
  showPreviousButton.value = newScrollLeft > arrowButtonToleranceToHide;

  showNextButton.value =
    newScrollLeft + filterContentWrapperElement.clientWidth + arrowButtonToleranceToHide <
    filterContentWrapperElement.scrollWidth;
}

function navigationFilterHandler(left: boolean) {
  const navigationJump = 185;
  const filterContentWrapperElement = filterContentWrapper.value as HTMLElement;
  const scrollLeft = filterContentWrapperElement.scrollLeft;
  const newScrollLeft = left ? scrollLeft - navigationJump : scrollLeft + navigationJump;
  filterContentWrapperElement.style.scrollBehavior = 'smooth';
  filterContentWrapperElement.scrollLeft = newScrollLeft;
  updateArrowButtons(newScrollLeft);
}
</script>

<template>
  <div class="relative">
    <ConversationIndexStatusFilterArrow
      :visible="showPreviousButton"
      id="arrow-button-left"
      data-test-id="arrow-button-left"
      direction="left"
      @click="navigationFilterHandler(true)"
    />
    <div class="relative flex space-x-2 overflow-x-hidden" ref="filterContentWrapper">
      <ConversationIndexDispositionChip
        v-for="statusFilterOption in statusFilterOptions"
        :key="statusFilterOption.value"
        :is-selected="isSelected(statusFilterOption.value)"
        :text="statusFilterOption.text"
        :project="currentProject"
        :count="getStatusCandidatesCount(statusFilterOption.value as keyof JobApplicantStatusCount)"
        :icon="statusFilterOption.icon"
        @click="selectStatus(statusFilterOption.value)"
      />
    </div>
    <ConversationIndexStatusFilterArrow
      :visible="showNextButton"
      id="arrow-button-right"
      data-test-id="arrow-button-right"
      direction="right"
      @click="navigationFilterHandler(false)"
    />
  </div>
</template>
