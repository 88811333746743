<script setup lang="ts">
import { ref, watch } from 'vue';

import ProjectCopilotSwitch from '@/components/Shared/Projects/ProjectCopilotSwitch.vue';
import copilotBannerBackground from '@/assets/png/copilot-banner.png';
import ProjectPremiumTag from '@/components/Shared/Projects/ProjectPremiumTag.vue';

const props = defineProps<{
  modelValue: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
}>();

const localModelValue = ref<boolean>(props.modelValue);

watch(localModelValue, (value) => {
  emit('update:modelValue', value);
});
</script>

<template>
  <div
    class="relative box-border h-[182px] rounded-xl bg-cover p-4 text-white md:h-[142px]"
    :style="{
      backgroundImage: `url(${copilotBannerBackground})`,
    }"
  >
    <p class="mb-2 font-serif text-base font-black leading-4">Need to supercharge your hiring?</p>
    <p class="mb-4 max-w-[380px] font-sans text-sm font-normal leading-[21px]">
      Use FactoryFix Copilot to help you source, score and screen your candidates.
      <a href="https://www.factoryfix.com/copilot" target="_blank" class="underline">Learn More</a>
    </p>
    <div><ProjectCopilotSwitch v-model="localModelValue" /></div>
    <ProjectPremiumTag class="absolute right-[16px] top-[16px]" />
  </div>
</template>
