<script setup lang="ts">
import copilotSwitch from '@/assets/svg/copilot-switch.svg';

defineProps<{
  modelValue: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
}>();

function updateValue(e: Event) {
  const target = e?.target as HTMLInputElement;
  emit('update:modelValue', Boolean(target.checked));
}
</script>

<template>
  <label class="relative box-border inline-flex h-[30px] cursor-pointer items-center">
    <input
      type="checkbox"
      :value="modelValue"
      class="sr-only"
      :checked="modelValue"
      @input="updateValue"
    />
    <div
      class="flex h-[30px] w-[52px] items-center rounded-[32px] border-[1px] p-[2px]"
      :class="{
        'border-[#ffffff1a] bg-[#ffffff4d]': !modelValue,
        'border-primary-200 bg-primary-300': modelValue,
      }"
    >
      <img
        :src="copilotSwitch"
        height="24px"
        with="24px"
        class="transition-transform"
        :class="{
          'translate-x-[22px]': modelValue,
        }"
      />
    </div>
    <span class="ml-2 text-sm font-bold leading-[21px]">Enable Copilot</span>
  </label>
</template>
