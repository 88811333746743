import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useAuthenticationStore = defineStore(
  'authentication',
  () => {
    const accessToken = ref<string>();
    const expiresAt = ref<number>();
    const tokenRefreshTimerId = ref<NodeJS.Timeout>();

    function clear(): void {
      accessToken.value = undefined;
      expiresAt.value = undefined;

      if (tokenRefreshTimerId.value) {
        clearTimeout(tokenRefreshTimerId.value);
      }
    }

    return {
      accessToken,
      expiresAt,
      tokenRefreshTimerId,
      clear,
    };
  },
  { persist: true },
);
